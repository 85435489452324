<template>
  <n-grid cols="1 s:10" responsive="screen" style="padding-top: 30px">
    <n-gi span="8" offset="0 s:1"
      ><Table
        :data="data"
        :fieldsToHide="['id']"
        :globalActions="maintenanceGlobalActions"
        :itemsActions="maintenanceItemActions" /></n-gi
  ></n-grid>
</template>
<script>
import { defineComponent, computed, onMounted } from "vue";
import { AddOutline as NewItemIcon, PencilOutline as EditIcon } from "@vicons/ionicons5";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { NGrid, NGi } from "naive-ui";
import Table from "@/components/TableBeta.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { Table, NGrid, NGi },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();

    onMounted(async () => {
      await store.dispatch("maintainer_category/fillCategories");
    });

    const dataRef = computed(() =>
      store.state.maintainer_category.categories.map((category) => {
        return {
          id: category.id,
          [t("commons.labels.name")]: category.name,
          [t("categories.createUpdate.form.showInHome.label")]: category.showInHome
            ? t("commons.confirmation.yes")
            : t("commons.confirmation.no"),
        };
      })
    );

    return {
      maintenanceGlobalActions: computed(() => {
        return [
          {
            label: "Add",
            key: "add",
            icon: NewItemIcon,
            props: {
              onClick: () => {
                router.push({ name: "create-category" });
              },
            },
          },
        ];
      }),
      maintenanceItemActions: (item) => {
        let actions = [];
        actions.push({
          label: "Update",
          key: "update",
          icon: EditIcon,
          props: {
            onClick: () => {
              router.push({
                name: "update-category",
                params: { categoryId: item.id, selectedCategory: item },
              });
            },
          },
        });
        return actions;
      },
      data: dataRef,
    };
  },
});
</script>
